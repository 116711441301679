import React from "react"

export default ({ pageContext }) => {
  const { title } = pageContext

  return (
    <>
      <h1>{title}</h1>
      <p className="folder-content">
        Vælg video i menuen til venstre
      </p>

      <p className="folder-content-mobile">
        Vælg video i menuen nedenfor
      </p>
    </>
  )
}